import mainConfig from "@config/domains";
import { logError, logInfo, logTitle } from "@data/console/colors-log";
import { checkCookies, getCookie } from "cookies-next";
import { getConfigApi } from "pages/api/layout/getConfig";

let domainPrefixesToIgnore = ['www.'];

export const getDomainConfig = (): DomainsConfigType | null => {
  if (typeof window == "undefined") {
    return null;
  }
  try {
    const hostname = window.location.hostname;
    const configDomains = mainConfig;

    const config: any = configDomains?.find((f) => f.domain == hostname);
    return config;
  } catch (error) {
    logError("getDomainConfig", error);
    return null;
  }
};
export const getDomainConfigBackend = (
  req: any
): DomainsConfigType | null | undefined => {
  logTitle("getDomainConfigBackend");
  let domain = req?.headers?.origin || req?.headers?.referer;
  console.log("🚀 ~ domain >>>>>>>>>>:", domain);
  try {
    domain = new URL(domain);
    
    const configDomains = mainConfig;
    console.log('domain.hostname --> ', domain.hostname)
    const config: DomainsConfigType | undefined = configDomains.find(
      (f) => {
        let alternateHostnames = domainPrefixesToIgnore.map(prefix => {
          if (f.domain.startsWith(prefix)) return f.domain.substring(prefix.length);
          return null;
        }).filter(x => x != null);
        console.log('alternateHostnames --> ', alternateHostnames);
        return (f.domain == domain.hostname) || (alternateHostnames.find(x => x == domain.hostname))
      }
    );
    logInfo("getDomainConfigBackend find =>>", config?.domain);

    return config;
  } catch (error) {
    logError("getDomainConfigBackend", JSON.stringify(error));
    logError("getDomainConfigBackend req", JSON.stringify(req));
    return null;
  }
};

export const getDomainConfigBackendByDomain = (
  domainData: string
): DomainsConfigType | null | undefined => {
  try {
    const domain = new URL(domainData);
    const configDomains = mainConfig;
    const config: DomainsConfigType | undefined = configDomains.find(
      (f) => f.domain == domain.hostname
    );

    return config;
  } catch (error) {
    logError("getDomainConfigBackendByDomain", JSON.stringify(error));
    return null;
  }
};
export const getAvailableOrgs = async (
  req: any,
  res: any
): Promise<number[]> => {
  let orgs: number[] = [];
  let orgId = 0;
  let domain = req?.headers?.origin || req?.headers?.referer;
  domain = new URL(domain);
  const configDomains = mainConfig;
  const config: DomainsConfigType | undefined = configDomains.find(
    (f) => f.domain == domain.hostname
  );

  if (checkCookies("wlOrg", { req, res })) {
    orgId = Number(getCookie("wlOrg", { req, res }));
  } else {
    const data: { data?: ConfigApiType | null } = await getConfigApi(req, res);
    if (Number(data?.data?.orgBySite?.orgId) > 0) {
      orgId = Number(data?.data?.orgBySite?.orgId);
    }
  }

  const mainOrgid = config?.orgId || null;
  const multiOrgIds = config?.orgList || null;

  if (mainOrgid) {
    orgs.push(Number(mainOrgid));
  }

  if (multiOrgIds) {
    multiOrgIds.map((data) => {
      if (data.orgId && !orgs.includes(Number(data.orgId))) {
        orgs.push(Number(data.orgId));
      }
    });
  }

  if (orgId > 0) {
    orgs.push(Number(orgId));
  }

  if (orgs.length >= 2) {
    let cleaned = orgs.filter((item, index) => orgs.indexOf(item) === index);

    return cleaned;
  }

  return orgs;
};
export const getOrgConfigExternalBackend = async (
  url: string,
  req: any,
  res: any
): Promise<DomainsConfigType | null> => {
  logTitle("getDomainConfigBackend");
  let orgId = 0;
  if (checkCookies("wlOrg", { req, res })) {
    orgId = Number(getCookie("wlOrg", { req, res }));
  } else {
    const data: { data?: ConfigApiType | null } = await getConfigApi(req, res);
    if (Number(data?.data?.orgBySite?.orgId) > 0) {
      orgId = Number(data?.data?.orgBySite?.orgId);
    }
  }
  if (orgId > 0) {
    return await fetch(`${url}/v1/wl/config/${orgId}`)
      .then((response) => response.json())
      .then((response) => response.data)
      .catch(() => null);
  }
  return null;
};
export const BACKEND_PATH = getDomainConfig()?.backendPath || "";

export const IMAGE_PATH =
  getDomainConfig()?.bannerPath ||
  "https://hoteltravreadyservices.inspiratest.com/images/";

export const PAYMENT_PATH = getDomainConfig()?.paymentPath || "";
export const SITE_ID = getDomainConfig()?.siteID || "";
export const PAGE_ID = getDomainConfig()?.pageID || "";
export const GOOGLE_MAPS_API = getDomainConfig()?.googleMapsID || "";
export const GOOGLE_APP_ID = getDomainConfig()?.googleAppID || "";
export const FACEBOOK_APP_ID = getDomainConfig()?.facebookAppID || "";
export const SHOW_TAX_INFO_POPUP =
  getDomainConfig()?.showTaxInfoPopup || "false";
export const PATH_PG_SWITH = getDomainConfig()?.pathPgV2 || "";

export const GIFT_CARD_LABEL = getDomainConfig()?.giftCardLabel || "";
export const GIFT_CARD_TYPE_ID = getDomainConfig()?.giftCardTypeID || "";

export const CERTIFICATE_CARD_LABEL =
  getDomainConfig()?.certificateCardLabel || "";
export const CERTIFICATE_CARD_TYPE_ID =
  getDomainConfig()?.certificateCardTypeID || "";

export const CARD_LABEL = getDomainConfig()?.cardLabel || "";
export const CARD_TYPE_ID = getDomainConfig()?.cardTypeID || "";

export const SEARCH_TYPE = getDomainConfig()?.searchType || "";
export const DISPLAY_ADS = getDomainConfig()?.displayAds || false;
export const SHOW_ALL_ELEGIBLE_PRODS =
  getDomainConfig()?.showAllElegibleProducts || false;

export const EXTERNAL_PATHS = getDomainConfig()?.externalPaths || [];
export const IS_B2B = getDomainConfig()?.isB2B || false;
export const USER_TYPE = getDomainConfig()?.userType || 2;

export const IS_SSO = getDomainConfig()?.isSSO || false;

export const IS_RESORT_IFRAME = getDomainConfig()?.isResortIframe;
export const ENABLE_HOLIDAYSTAYS_WIDGET =
  getDomainConfig()?.enableHolidaystaysWidget;
export const SUPPLIER_NAME = getDomainConfig()?.supplierName;

export const SHOW_SUPPLIER_NAME_STRIKE =
  getDomainConfig()?.showSupplierNameStrike === false ? false : true;

export const USE_WEEKS_V1 = getDomainConfig()?.useWeeksV1 || false;
export const WEB_SOCKET_URL =
  getDomainConfig()?.webSocketUrl || "https://websocket.inspirayouth.com";
export const WEB_SOCKET_INSPIRA_URL =
  getDomainConfig()?.webSocketInspiraUrl ||
  "https://apis.inspiratest.com/wss/bookings";

export const IS_VOYVIO = getDomainConfig()?.isVoyvio || false;
export const IS_PRESTIGE = getDomainConfig()?.isPrestige || false;

export const IS_ZUPERHOTELES = getDomainConfig()?.isZuperHoteles || false;

export const SHOW_REGISTER_CODE = [false, "false"].includes(
  String(getDomainConfig()?.showRegisterCode)
)
  ? false
  : true;

export const IS_SPORTSNTRAVEL = getDomainConfig()?.isSportsTravel || false;
//export const IS_SPORTSNTRAVEL = true;

export const FLIGHT_TERMS_AND_CONDITIONS_DOC_ID =
  getDomainConfig()?.flightTermsAndConditionsDocId || "";

export const TIMER = getDomainConfig()?.timer || 15;
export const RESORTS_TIMER = getDomainConfig()?.resortTimer || 30;
export const CRISP_ID = getDomainConfig()?.crispChatId || null;
export const SITE = getDomainConfig()?.domain || "";

export const WEEKS_SSO =
  getDomainConfig()?.weeksSso || "https://condotc.inspiratest.com";

export const WEEKS_SSO_APIKEY =
  getDomainConfig()?.weeksSsoApikey || "c4bdc66e-a3f0-4418-80a6-318542a96834";

export const WEEKS_SSO_ENCRYPT =
  getDomainConfig()?.weeksSsoEncrypt ||
  "https://crypto.inspirayouth.com/encrypt";

export const CRUISES_DEF_SSO =
  getDomainConfig()?.cruisesSso || "https://cruise.inspiravacations.com";

export const CRUISES_DEF_SSID = getDomainConfig()?.cruisesSiid || "878954";

export const CRUISES_DEF_REDIRECT =
  getDomainConfig()?.cruisesSsoRedirect ||
  "https://cruise.inspiravacations.com";

export const EXTRAS_PATHS = getDomainConfig()?.extrasPaths || [];

export const DRIFT_CHAT = getDomainConfig()?.driftChat;

export const AUTOSUGGESTPATH = getDomainConfig()?.autoSuggestPath || "";
export const SHOW_GOOGLE =
  getDomainConfig()?.showGoogleSignInSignUp == "false" ? false : true;
export const SHOW_FACEBOOK =
  getDomainConfig()?.showFacebookSignInSignUp == "false" ? false : true;
export const FAVICON_URL = getDomainConfig()?.faviconUrl;
export const LOADER_URL = getDomainConfig()?.loaderUrl;
export const LOGO_URL = getDomainConfig()?.logoUrl;
export const ORG_ID = getDomainConfig()?.orgId;
export const SITE_TITLE = getDomainConfig()?.siteTitle;
export const FLIGHT_SEARCH_INVENTORY_TYPE =
  getDomainConfig()?.flightSearchInventoryType || "flight";
export const UPGRADE_OPTIONS = getDomainConfig()?.upgradeOptions;
export const MARKUP_RATE = getDomainConfig()?.markupRate || 1.1;
export const FLIGHTS_LOGIN_REQUIRED = getDomainConfig()?.flightsLoginRequired;
export const SHOW_WALLET =
  getDomainConfig()?.showWallet == undefined
    ? true
    : getDomainConfig()?.showWallet;
export const SHOW_EXTRA_PATHS =
  getDomainConfig()?.showExtraPaths == undefined
    ? true
    : getDomainConfig()?.showExtraPaths;
export const ORG_LIST = getDomainConfig()?.orgList;

export const getConfigByWlOrg = (orgId: string) => {
  return ORG_LIST?.find((f) => f.orgId == orgId);
};

export const HIDE_SSO_PHONE_MODAL =
  getDomainConfig()?.hideSsoPhoneCompleteInfo || false;

export const getMenuBarByOrgId = (orgId: string) => {
  console.info("orgId on getCondif", orgId);
  if (orgId) {
    let orgObj = ORG_LIST?.find((f) => f.orgId == orgId);
    if (orgObj) {
      console.info("orgId on getCondif enrtre if", orgId, orgObj);
      return orgObj;
    } else {
    }
  }
};

export const getConfigByOrgIdByWeeks = (orgId: string) => {
  console.info("orgId on getCondif", orgId);
  if (orgId) {
    let orgObj = ORG_LIST?.find((f) => f.orgId == orgId);
    console.info("orgId on getCondif enrtre", orgId, orgObj);

    if (orgObj) {
      console.info("orgId on getCondif enrtre if", orgId, orgObj);
      return orgObj;
    } else {
      console.info("orgId on getCondif enrtre else", orgId, orgObj);

      return {
        weeks: {
          type: "def",
          weeksSso: WEEKS_SSO,
          weeksSsoApikey: WEEKS_SSO_APIKEY,
          weeksSsoEncrypt: WEEKS_SSO_ENCRYPT,
        },
      };
    }
  }
  return {
    weeks: {
      type: "def",
      weeksSso: WEEKS_SSO,
      weeksSsoApikey: WEEKS_SSO_APIKEY,
      weeksSsoEncrypt: WEEKS_SSO_ENCRYPT,
    },
  };
};

export const getConfigByWlOrgCookie = () => {
  if (typeof window == "undefined") {
    return undefined;
  }
  let orgId = String(getCookie("wlOrg"));
  return getConfigByWlOrg(orgId);
};
// WEEKS BY ORG URL
export const WEEKS_SSO_URL =
  getConfigByWlOrgCookie()?.weeks?.weeksSso ?? WEEKS_SSO;

//CRUISES BY ORG URL
export const CRUISES_SSO_URL =
  getConfigByWlOrgCookie()?.cruises?.cruisesSso || CRUISES_DEF_SSO;

export const CRUISES_SSID =
  getConfigByWlOrgCookie()?.cruises?.cruisesSiid || CRUISES_DEF_SSID;

export const CRUISES_SSO_REDIRECT =
  getConfigByWlOrgCookie()?.cruises?.cruisesSsoRedirect || CRUISES_DEF_REDIRECT;

// Extras BY ORGID
export const CUSTOMER_EMAIL_SUPPORT =
  getConfigByWlOrgCookie()?.customerEmailSupport ||
  getDomainConfig()?.customerEmailSupport;

export const CUSTOMER_PHONE_SUPPORT =
  getConfigByWlOrgCookie()?.customerPhoneSupport ||
  getDomainConfig()?.customerPhoneSupport;
export const DAILY_USER_ORG_KEY =
  getDomainConfig()?.dailydealsConfig?.dailyUserOrganizationKey;
export const DAILY_USER_PROGRAM_KEY =
  getDomainConfig()?.dailydealsConfig?.dailyUserProgramKey;
export const DAILY_COMPANY_ID =
  getDomainConfig()?.dailydealsConfig?.dailyCompanyId;
export const DAILY_REDIRECT_PATH =
  getDomainConfig()?.dailydealsConfig?.dailydealsRedirect;
export const DAILY_BACKEND_PATH =
  getDomainConfig()?.dailydealsConfig?.dailyBackendPath;

export const DAILY_CONFIG = getDomainConfig()?.dailydealsConfig;
export const ACTIVITIES_POLICIES_DOC_ID =
  getDomainConfig()?.activitiesPoliciesDocId || "";

/* SHOW CASH CARD BY CONFIG JSON */
export const SHOW_CARSHCARD_BY_SERVICE: boolean =
  getDomainConfig()?.servicesCashCardAvailable === undefined
    ? true
    : !!getDomainConfig()?.servicesCashCardAvailable;

export const SHOW_KEYS_LANG_LABELS =
  getDomainConfig()?.showKeysLangLabel || false;

export const MAPID = getDomainConfig()?.mapId || false;

/* PERSONAL WALLET */
/* export const ENABLED_PERSONAL_WALLET: boolean =
  getDomainConfig()?.enabledPersonalWallet || false; */
export const ENABLED_PERSONAL_WALLET: boolean = true;
const META_DESCRIPTION_DEFAULT =
  "Over 1,800,000 hotels around the world with savings of up to 60% off. Rewards benefits everytime you book. Concierge Service.";
export const META_DESCRIPTION =
  getDomainConfig()?.meta?.description || META_DESCRIPTION_DEFAULT;

const META_KEYWORDS_DEFAULT = "Hotels, Cars, Flights, Activities, Transfers";
export const META_KEYWORDS =
  getDomainConfig()?.meta?.keywords || META_KEYWORDS_DEFAULT;

const META_AUTHOR_DEFAULT = "Inspira Vacations";
export const META_AUTHOR =
  getDomainConfig()?.meta?.author || META_AUTHOR_DEFAULT;

const META_IMAGE_DEFAULT = "Main2_Hotels.jpg";
export const META_IMAGE = getDomainConfig()?.meta?.image || META_IMAGE_DEFAULT;

export const SHOW_BRANDED_FARE: boolean =
  getDomainConfig()?.enableBrandedFareFlights || false;

export const VR_WIDGET: vrWidgetType | null =
  getDomainConfig()?.vrWidget || null;

export const ROYAL_MC: RoyaMCType[] | null = getDomainConfig()?.royalMC || [];

export const ROYAL_EMP_MC: string | null =
  getDomainConfig()?.royalEmployeeMc || "RoyalEmployee";

export const DREAM_TRIPS_URL: string | null =
  getDomainConfig()?.dreamtripsIframeUrl || "";

export const GENERAL_API_KEY: string | null =
  getDomainConfig()?.generalApiKey || "";

export const getOrgConfig = (orgId: string) => {
  if (orgId) {
    let orgObj = ORG_LIST?.find((f) => String(f.orgId) === String(orgId));
    if (orgObj) {
      return orgObj.externalPaths as ItemMenu[];
    }
  }
  return EXTERNAL_PATHS as ItemMenu[];
};
